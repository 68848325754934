<template>
  <div :class="styles">
    <div class="shrink-0">
      <component :is="icon" v-if="icon" :class="iconStyles" />
    </div>

    <div :class="bodyStyles">
      <h5 class="font-semibold leading-none tracking-tight">
        {{ title }}
      </h5>

      <div class="mt-2 text-sm">
        <slot name="description">
          <p>{{ description }}</p>
        </slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Component } from 'vue'

const props = withDefaults(
  defineProps<{
    icon?: Component
    title: string
    description?: string
    variant?: 'default' | 'outline' | 'danger' | 'success'
  }>(),
  {
    icon: undefined,
    description: undefined,
    variant: 'default',
  }
)

const iconStyles = computed<string[]>(() => {
  const base = '-mx-0.5 -my-0.5 size-5 shrink-0'

  const colors = {
    default: 'text-gray-950',
    outline: 'text-gray-950',
    danger: 'text-red-800',
    success: 'text-emerald-800',
  }

  return [base, colors[props.variant]]
})

const styles = computed<string[]>(() => {
  const base = 'relative flex w-full rounded-md border px-4 py-3 text-sm'

  const colors = {
    default: 'border-gray-200 bg-gray-50 text-gray-950',
    outline: 'border-gray-200 text-gray-950',
    danger: 'border-red-200 bg-red-50 *:text-red-700',
    success: 'border-emerald-200 bg-emerald-50 *:text-emerald-700',
  }

  return [base, colors[props.variant]]
})

const bodyStyles = computed<string[]>(() => {
  const base = 'ml-3'

  const colors = {
    default: '[&>*]:text-gray-600 [&>h5]:text-gray-950',
    outline: '[&>*]:text-gray-600 [&>h5]:text-gray-950',
    danger: '[&>*]:text-red-700 [&>h5]:text-red-800',
    success: '[&>*]:text-emerald-700 [&>h5]:text-emerald-800',
  }

  return [base, colors[props.variant]]
})
</script>
